import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  Steps,
  theme,
  Button,
  message,
  InputNumber,
  Spin,
  Checkbox,
  Radio,
  Upload,
  DatePicker,
} from "antd";
import moment from "moment/moment";
import { counties } from "../../../counties";
import {
  handleNextStep,
  handlePrevStep,
} from "../../../features/profile/profileSlice";
import eduImg from "../../../assets/edit_edu.png";
import {
  fetchProfessional,
  saveEducation,
} from "../../../features/edu/eduSlice";
import { getFileNameFromUrl } from "../../../utils";

const { TextArea } = Input;

let countyOptions = [];

counties.forEach((item) => {
  countyOptions.push({
    label: item?.name,
    value: item?.name,
  });
});

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

export default function ProfessionalQualification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { educations, eduLoading } = useSelector((state) => state.edu);

  const [data, setdata] = useState({});
  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const [addEducation, setaddEducation] = useState(false);
  const [educationList, seteducationList] = useState(educations);
  const [editedItem, seteditedItem] = useState({});
  const [editing, setediting] = useState(false);

  const usrObj = {
    usrId: user?.usrId,
  };

  const props = {
    name: "file",
    multiple: false,
    accept: "image/png, image/jpeg, .pdf",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleClearDate = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      eduStartDate: null,
      eduEndDate: null,
    }));
  };

  const handleChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleEduLevelChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      eduLevel: value,
    }));
  }

  function onMonthChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      eduStartDate: value,
    }));
  }

  function onYearChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      eduStartYear: moment(value?._d).format("YYYY"),
    }));
  }

  function onEndMonthChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      endEndDate: value,
    }));
  }

  function onEndYearChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      eduEndMonth: moment(value?._d).format("YYYY"),
    }));
  }

  function handleGoNext() {
    dispatch(handleNextStep());
  }

  function handleGoBack() {
    form.resetFields();
    seteditedItem({});
    setaddEducation(false);
    dispatch(handlePrevStep());
  }

  async function handleCancel() {
    await seteditedItem({});
    // await form2.resetFields()
    await setediting(false);
    await setaddEducation(false);
  }

  async function handleaddNew() {
    await seteditedItem({});
    await setediting(false);
    await setaddEducation(true);
  }

  async function handleEditItem(e, item) {
    await e.preventDefault();
    await seteditedItem(item);
    await setediting(true);
    await setaddEducation(true);
  }

  const onFinish = async (values) => {
    values.eduStartMonth = moment(values?.month?.$d).format("MMMM");
    values.eduStartYear = values?.year?.$y;

    values.eduEndMonth = moment(values?.end_month?.$d).format("MMMM");
    values.eduEndYear = values?.end_year?.$y;

    values.eduUsrId = user?.usrId;
    values.eduType = "PROFESSIONAL";

    await dispatch(saveEducation(values));
    const resp = await dispatch(fetchProfessional(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }

    await setaddEducation(false);
    await form.resetFields();
  };

  const onFinish2 = async () => {
    await dispatch(saveEducation(editedItem));
    const resp = await dispatch(fetchProfessional(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }

    await setaddEducation(false);
    await form.resetFields();
  };

  function disabledFutureDate(current) {
    // Disable dates after the current month
    return current && current > moment().endOf("month");
  }

  const eduLevelOptions = [
    {
      label: "None",
      value: "None",
    },
    {
      label: "KCPE",
      value: "KCPE",
    },
    {
      label: "KCSE",
      value: "KCSE",
    },
    {
      label: "Certificate",
      value: "Certificate",
    },
    {
      label: "Diploma",
      value: "Diploma",
    },
    {
      label: "Higher diploma",
      value: "Higher diploma",
    },
    {
      label: "Degree",
      value: "Degree",
    },
    {
      label: "Masters",
      value: "Masters",
    },
    {
      label: "PhD",
      value: "PhD",
    },
  ];

  async function handleFetchEducations() {
    const resp = await dispatch(fetchProfessional(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }
  }

  useEffect(() => {}, [educationList, editedItem]);

  useEffect(() => {
    seteducationList(educations);
  }, [educations]);

  useEffect(() => {
    handleFetchEducations();
  }, []);

  return (
    <>
      <div className='mt-10'>
        <p className='edit-step-desc mt-2'>
          List professional qualification you’ve attained below
        </p>
        <div className='flex justify-end w-full'>
          {addEducation ? null : (
            <button
              onClick={handleaddNew}
              className='bg-transparent flex items-center border-none'
            >
              <div className='bg-darkBlue rounded-full p-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clip-rule='evenodd'
                    d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
                    fill='white'
                  />
                  <path
                    fillRule='evenodd'
                    clip-rule='evenodd'
                    d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
                    fill='white'
                  />
                </svg>
              </div>
              <span className='text-darkBlue font-medium text-[17px] ml-3'>
                Add Qualification
              </span>
            </button>
          )}
        </div>

        {educationList?.length && !addEducation ? (
          <>
            {educationList?.map((item) => {
              return (
                <>
                  <a onClick={(e) => handleEditItem(e, item)} href='#to'>
                    <div className='bg-white p-6 mb-4 mt-10'>
                      <div className='w-full flex flex-col'>
                        <h3 className='text-darkBlue text-[21px] font-bold'>
                          {item?.eduLevel}
                        </h3>
                        <p className='text-black333 font-medium text-[18px] my-1'>
                          {item?.eduSchool}
                        </p>
                        <span className='text-black333 font-normal text-[19px]'>
                          {moment(item?.endEndDate).format("YYYY")}
                        </span>
                      </div>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        className='prof-cert-text text-justify mt-7'
                        href={url + item?.eduDesc}
                      >
                        {getFileNameFromUrl(item?.eduDesc)}
                      </a>
                    </div>
                  </a>
                </>
              );
            })}

            <div className='mt-20'></div>

            <div className='absolute bottom-10 flex justify-center items-center  w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : !addEducation && !educationList?.length ? (
          <>
            <div className='flex items-center justify-center flex-col'>
              <img
                className='object-contain w-[550px] h-auto'
                src={eduImg}
                alt='education'
              />
              <p className='edit-step-desc my-10'>
                Don’t have education experience? You can skip this part
              </p>
            </div>

            <div className='flex items-center justify-center mt-10 mb-14 w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : editing ? (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish2}
            style={{
              maxWidth: "100%",
              marginTop: 20,
            }}
            form={form2}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <div className='flex flex-col'>
                <label className='mb-2'>Education Level</label>
                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  value={editedItem?.eduLevel}
                  options={eduLevelOptions}
                  onChange={(value) => {
                    handleEduLevelChange(value);
                  }}
                  onDropdownVisibleChange={() => {
                    //trigger something
                  }}
                />
              </div>

              <div className='flex flex-col'>
                <label className='mb-2'>Name of School</label>
                <Input
                  onChange={handleChange}
                  name='eduSchool'
                  value={editedItem?.eduSchool}
                  className='rounded-[8px] h-[44px] w-full border border-black'
                />
              </div>

              <div className='mt-4'>
                <p className='mb-3 font-medium'>Start of Education</p>
                <DatePicker
                  format={"MMMM - YYYY"}
                  placeholder={
                    editedItem?.eduStartDate
                      ? moment(editedItem?.eduStartDate).format("MMMM - YYYY")
                      : "Select start date"
                  }
                  className='w-full'
                  onChange={onMonthChange}
                  picker='month'
                />
              </div>

              <div className='mt-4'>
                <p className='mb-3 font-medium'>End of Education</p>
                <DatePicker
                  format={"MMMM - YYYY"}
                  placeholder={
                    editedItem?.endEndDate
                      ? moment(editedItem?.endEndDate).format("MMMM - YYYY")
                      : "Select start date"
                  }
                  className='w-full'
                  onChange={onEndMonthChange}
                  picker='month'
                />
              </div>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        ) : (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: 20,
            }}
            form={form}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <Form.Item
                name='eduLevel'
                label='Qualification '
                rules={[
                  {
                    required: true,
                    message: "Qualification attained is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>

              <Form.Item
                name='eduSchool'
                label='Name of Institution'
                rules={[
                  {
                    required: true,
                    message: "Name of Institution is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>
              <Form.Item
                label='Year of Qualification'
                name='endEndDate'
                rules={[
                  {
                    required: true,
                    message: "Year of Qualification required",
                  },
                ]}
                className='mr-6 w-full'
              >
                <DatePicker
                  placeholder='Select year'
                  className='mr-3'
                  format={"YYYY"}
                  picker='year'
                  disabledDate={disabledFutureDate}
                />
              </Form.Item>
            </div>
            <div className='flex flex-col mb-5'>
              <label className='mb-2'>Certificate</label>
              <Dragger
                defaultFileList={[
                  {
                    uid: editedItem?.attId,
                    name: editedItem?.attFileName,
                    status: "done",
                    url: url + editedItem?.attFile,
                  },
                ]}
                {...props}
              >
                <div className='flex justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='29'
                    viewBox='0 0 29 29'
                    fill='none'
                  >
                    <path
                      d='M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125'
                      stroke='#147CBC'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M20.5423 9.66667L14.5007 3.625L8.45898 9.66667'
                      stroke='#147CBC'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M14.5 3.625V18.125'
                      stroke='#147CBC'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                  <p className='ant-upload-text !ml-2'>Upload pdf or jpeg</p>
                </div>
              </Dragger>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}
