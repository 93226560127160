export default function ChooseDocPage() {

	return (
		<>
			<div className='m-[10px] md:mr-[108px] md:ml-[42px] md:mt-[15px]'>
				<div>
					<h2 className='prof-name'>
						Let’s <span className='text-[#147CBC]'>build</span> your CV.
					</h2>
					<p className='prof-desc mt-2'>
						A strong CV will help you stand out to employers.
					</p>

					<p className='cv-desc mt-7 mb-5'>
                    The following information will appear on your CV
					</p>
				</div>
			</div>
		</>
	);
}
