import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { handleSidenavFullWidth } from "../../features/global/globalSlice";
import checked from "../../assets/checked.png";
import { Descriptions, Dropdown, Select, Switch, Tabs } from "antd";
import ExperienceTab from "./tabs/ExperienceTab";
import EducationTab from "./tabs/EducationTab";
import CertificateTab from "./tabs/CertificateTab";
import { handleStepCurrent } from "../../features/profile/profileSlice";
import {
  fetchUserDisabilitys,
  saveUserDisability,
  updateUser,
} from "../../features/auth/authSlice";
import kenyaEmblem from "../../assets/emblem.png";
import {
  fetchJobSeekerInfos,
  saveJobSeekerInfo,
} from "../../features/job/jobSlice";
import moment from "moment";

export default function MainProfile({ profileApplication }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, disability } = useSelector((state) => state.auth);
  const { seekingInfo } = useSelector((state) => state.job);

  const [editProfile, seteditProfile] = useState(false);
  const [disabilityObj, setdisabilityObj] = useState(disability);
  const [seekeObj, setseekeObj] = useState(seekingInfo);

  const settingItems = [
    {
      key: "1",
      label: (
        <Link onClick={(e) => handleProfileBuilder(e)} className='' to='#'>
          Edit Profile
        </Link>
      ),
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M5 17.998H9.24C9.37161 17.9987 9.50207 17.9735 9.62391 17.9238C9.74574 17.874 9.85656 17.8007 9.95 17.708L16.87 10.778L19.71 7.99799C19.8037 7.90502 19.8781 7.79442 19.9289 7.67256C19.9797 7.5507 20.0058 7.42 20.0058 7.28799C20.0058 7.15597 19.9797 7.02527 19.9289 6.90341C19.8781 6.78155 19.8037 6.67095 19.71 6.57799L15.47 2.28799C15.377 2.19426 15.2664 2.11986 15.1446 2.06909C15.0227 2.01833 14.892 1.99219 14.76 1.99219C14.628 1.99219 14.4973 2.01833 14.3754 2.06909C14.2536 2.11986 14.143 2.19426 14.05 2.28799L11.23 5.11799L4.29 12.048C4.19732 12.1414 4.12399 12.2522 4.07423 12.3741C4.02446 12.4959 3.99924 12.6264 4 12.758V16.998C4 17.2632 4.10536 17.5176 4.29289 17.7051C4.48043 17.8926 4.73478 17.998 5 17.998ZM14.76 4.40799L17.59 7.23799L16.17 8.65799L13.34 5.82799L14.76 4.40799ZM6 13.168L11.93 7.23799L14.76 10.068L8.83 15.998H6V13.168ZM21 19.998H3C2.73478 19.998 2.48043 20.1033 2.29289 20.2909C2.10536 20.4784 2 20.7328 2 20.998C2 21.2632 2.10536 21.5176 2.29289 21.7051C2.48043 21.8926 2.73478 21.998 3 21.998H21C21.2652 21.998 21.5196 21.8926 21.7071 21.7051C21.8946 21.5176 22 21.2632 22 20.998C22 20.7328 21.8946 20.4784 21.7071 20.2909C21.5196 20.1033 21.2652 19.998 21 19.998Z'
            fill='black'
          />
        </svg>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: <span className='profile-basic-info !font-[500]'>Full Name</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrFirstName} {user?.usrMiddleName} {user?.usrLastName}
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span className='profile-basic-info !font-[500]'>
          {user?.usrDocType} No.
        </span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrNationalId}
        </span>
      ),
    },
    {
      key: "3",
      label: <span className='profile-basic-info !font-[500]'>Email</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>{user?.usrEmail}</span>
      ),
    },
    {
      key: "4",
      label: (
        <span className='profile-basic-info !font-[500]'>Nationality</span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrNationality}
        </span>
      ),
    },
    {
      key: "5",
      label: <span className='profile-basic-info !font-[500]'>County</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrCounty}
        </span>
      ),
    },
    {
      key: "6",
      label: (
        <span className='profile-basic-info !font-[500]'>
          Registration Type
        </span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>Job seeker</span>
      ),
    },
    {
      key: "7",
      label: <span className='profile-basic-info !font-[500]'>ID Number</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrNationalId}
        </span>
      ),
    },
    {
      key: "8",
      label: <span className='profile-basic-info !font-[500]'>Mobile No.</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          +{user?.usrMobileNumber}
        </span>
      ),
    },
    {
      key: "9",
      label: (
        <span className='profile-basic-info !font-[500]'>Date of birth</span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {moment(user?.usrDob).format("DD MMM YYYY")}
        </span>
      ),
    },
    {
      key: "10",
      label: (
        <span className='profile-basic-info !font-[500]'>Marital status</span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrMaritalStatus}
        </span>
      ),
    },
    {
      key: "11",
      label: <span className='profile-basic-info !font-[500]'>Ethnicity</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrEthnicity}
        </span>
      ),
    },
    {
      key: "12",
      label: <span className='profile-basic-info !font-[500]'>Gender</span>,
      children: (
        <span className='profile-basic-info !font-[400]'>
          {user?.usrGender}
        </span>
      ),
    },
  ];

  const jobSeeker = [
    {
      key: "1",
      label: (
        <span className='profile-basic-info !font-[500]'>
          Job seeking methods{" "}
        </span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          Letter of application{" "}
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span className='profile-basic-info !font-[500]'>current job </span>
      ),
      children: <span className='profile-basic-info !font-[400]'>Self</span>,
    },
  ];

  const personDisability = [
    {
      key: "1",
      label: (
        <span className='profile-basic-info !font-[500]'>
          Disability present?
        </span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {disabilityObj?.disIsPresent ? "Yes" : "No"}
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span className='profile-basic-info !font-[500]'>Disability type</span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {disabilityObj?.disType ? disabilityObj?.disType : "N/A"}
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span className='profile-basic-info !font-[500]'>
          Certificate Number
        </span>
      ),
      children: (
        <span className='profile-basic-info !font-[400]'>
          {disabilityObj?.disCertNumber ? disabilityObj?.disCertNumber : "N/A"}
        </span>
      ),
    },
  ];

  const profileTabs = [
    {
      label: <span className='ml-2'>Experience</span>,
      key: "0",
      children: (
        <ExperienceTab
          profileApplication={profileApplication}
          editProfile={editProfile}
        />
      ),
    },
    {
      label: <span className='ml-2'>Education</span>,
      key: "1",
      children: (
        <EducationTab
          profileApplication={profileApplication}
          editProfile={editProfile}
        />
      ),
    },

    {
      label: <span className='ml-2'>Certificates</span>,
      key: "2",
      children: (
        <CertificateTab
          profileApplication={profileApplication}
          editProfile={editProfile}
        />
      ),
    },
  ];

  async function handleSelectChange(value) {
    let usrObj = { ...user, usrProfileVisibility: value };
    await dispatch(updateUser(usrObj));
  }

  const onChange = async (e) => {
    const usrObj = {
      usrId: user?.usrId,
      usrDisability: e.target.checked === true ? "True" : "False",
    };
    await dispatch(updateUser(usrObj));
  };

  async function handleProfileBuilder(e) {
    e.preventDefault();
    await dispatch(handleStepCurrent(0));
    await navigate("/profile-builder");
  }

  function handleEditProfile(e) {
    e.preventDefault();
    seteditProfile(true);
  }

  async function handleFetchDisability() {
    const usrObj = {
      disUsrId: user?.usrId,
    };
    const res = await dispatch(fetchUserDisabilitys(usrObj));
    if (res?.payload?.success) {
      setdisabilityObj(res?.payload?.data?.result[0]);
    }
  }

  async function handleFetchJobSeekerInfo() {
    const usrObj = {
      jsiUsrId: user?.usrId,
    };
    const res = await dispatch(fetchJobSeekerInfos(usrObj));
    if (res?.payload?.success) {
      setseekeObj(res?.payload?.data?.result[0]);
    }
  }

  const onJobSeekingChange = async () => {
    let objCopy = { ...seekeObj, jsiPrivacy: !seekeObj?.jsiPrivacy };
    const res = await dispatch(saveJobSeekerInfo(objCopy));
    if (res?.payload?.success) {
      handleFetchJobSeekerInfo();
    }
  };

  const onDisabilityChange = async () => {
    let objCopy = { ...disabilityObj, disPrivacy: !disabilityObj?.disPrivacy };
    const res = await dispatch(saveUserDisability(objCopy));
    if (res?.payload?.success) {
      handleFetchDisability();
    }
  };

  useEffect(() => {}, [user, disabilityObj]);

  useEffect(() => {
    setdisabilityObj(disability);
  }, [disability]);

  useEffect(() => {
    dispatch(handleSidenavFullWidth(false));
    handleFetchDisability();
    handleFetchJobSeekerInfo();
  }, []);

  return (
    <>
      <div className='m-[10px] md:mr-[108px] md:ml-[42px] md:mt-[15px]'>
        <div>
          <h2 className='prof-name'>
            {editProfile ? "Profile visibility" : "Profile"}
          </h2>
          <p className='prof-desc mt-2'>
            {editProfile
              ? "Manage who can see your profile"
              : "Fill in your profile."}
          </p>

          {editProfile ? (
            <>
              <div className='flex items-center justify-end'>
                <button
                  onClick={() => seteditProfile(false)}
                  type='button'
                  className='flex items-center justify-end'
                >
                  <span className='bg-[#EDF8FF] rounded-full p-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='28'
                      height='24'
                      viewBox='0 0 28 24'
                      fill='none'
                    >
                      <path
                        d='M17.7692 18L11.0469 12L17.7692 6'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </span>

                  <span className='ml-4 go-back'>Go Back</span>
                </button>
              </div>
            </>
          ) : (
            <div className='flex flex-row justify-end gap-10'>
              <div className='flex items-center justify-end'>
                <button
                  className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[199px] h-[46px] flex items-center justify-center'
                  onClick={(e) => navigate("/job-vacancies")}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    className='mt-3'
                  >
                    <path
                      d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
                      fill='#0170BD'
                    />
                  </svg>
                  <span className='ml-2'>Apply Job</span>
                </button>
              </div>

              <div className='flex items-center justify-end'>
                <button
                  className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[199px] h-[46px] flex items-center justify-center'
                  onClick={(e) => handleProfileBuilder(e)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M5 17.998H9.24C9.37161 17.9987 9.50207 17.9735 9.62391 17.9238C9.74574 17.874 9.85656 17.8007 9.95 17.708L16.87 10.778L19.71 7.99799C19.8037 7.90502 19.8781 7.79442 19.9289 7.67256C19.9797 7.5507 20.0058 7.42 20.0058 7.28799C20.0058 7.15597 19.9797 7.02527 19.9289 6.90341C19.8781 6.78155 19.8037 6.67095 19.71 6.57799L15.47 2.28799C15.377 2.19426 15.2664 2.11986 15.1446 2.06909C15.0227 2.01833 14.892 1.99219 14.76 1.99219C14.628 1.99219 14.4973 2.01833 14.3754 2.06909C14.2536 2.11986 14.143 2.19426 14.05 2.28799L11.23 5.11799L4.29 12.048C4.19732 12.1414 4.12399 12.2522 4.07423 12.3741C4.02446 12.4959 3.99924 12.6264 4 12.758V16.998C4 17.2632 4.10536 17.5176 4.29289 17.7051C4.48043 17.8926 4.73478 17.998 5 17.998ZM14.76 4.40799L17.59 7.23799L16.17 8.65799L13.34 5.82799L14.76 4.40799ZM6 13.168L11.93 7.23799L14.76 10.068L8.83 15.998H6V13.168ZM21 19.998H3C2.73478 19.998 2.48043 20.1033 2.29289 20.2909C2.10536 20.4784 2 20.7328 2 20.998C2 21.2632 2.10536 21.5176 2.29289 21.7051C2.48043 21.8926 2.73478 21.998 3 21.998H21C21.2652 21.998 21.5196 21.8926 21.7071 21.7051C21.8946 21.5176 22 21.2632 22 20.998C22 20.7328 21.8946 20.4784 21.7071 20.2909C21.5196 20.1033 21.2652 19.998 21 19.998Z'
                      fill='#147CBC'
                    />
                  </svg>
                  <span className='ml-2'>Edit Profile</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <div className='flex mt-5'>
          <div className='w-[35%]'>
            <div>
              <div className='card-main bg-white pb-14'>
                <div className='cover-photo-main'>
                  <img
                    src={
                      "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                    }
                    alt='profile'
                    className='profile-main'
                  />
                </div>
                <div className='flex flex-col mb-20 pt-8 px-[36px]'>
                  <div className='flex items-center mt-[15%]'>
                    <span className='text-3xl text-extrabold  w-full truncate'>
                      {user?.usrFullNames}
                    </span>

                    <img
                      className='w-11 object-contain ml-1'
                      src={checked}
                      alt='Verified'
                    />
                  </div>

                  <span className='prof-reg mt-2'>REG: {user?.usrCode}</span>
                  <span className='prof-job  mt-5'>{user?.usrTitle}</span>
                  <span className='prof-loc mt-1'>
                    {user?.usrCounty}, Kenya
                  </span>

                  <div className='flex mt-10 mb-4'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_3177_18210)'>
                        <path
                          d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_3177_18210'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className='prof-card-head ml-3'>About me</span>
                  </div>

                  <span className='mt-2 prof-card-text'>
                    {user?.usrBio ? user?.usrBio : "N/A"}
                  </span>

                  {/* <div className='flex mt-10 mb-4'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
										>
											<g clipPath='url(#clip0_3177_18210)'>
												<path
													d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
													fill='black'
												/>
											</g>
											<defs>
												<clipPath id='clip0_3177_18210'>
													<rect width='24' height='24' fill='white' />
												</clipPath>
											</defs>
										</svg>
										<span className='prof-card-head ml-3'>Skills</span>
									</div>

									<div className='flex items-center flex-wrap mt-2'>
										<div className='bg-lightPurple rounded-[63px] px-5 py-1 mr-2'>
											<span className='prof-card-badge'>Python Django</span>
										</div>
									</div> */}

                  <div className='flex mt-10 mb-4'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_3177_18210)'>
                        <path
                          d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_3177_18210'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className='prof-card-head  ml-3'>Languages</span>
                  </div>

                  <div className='flex items-center flex-wrap mt-2'>
                    {user?.usrLanguages?.split(",").map((item) => {
                      return (
                        <>
                          <div
                            key={item}
                            className='bg-lightPurple rounded-[63px] text-center px-5 py-1 mr-2 mb-3'
                          >
                            <span className='prof-card-badge'>{item}</span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              {profileApplication || editProfile ? null : (
                <>
                  {/* <div className='pt-10 flex flex-col my-10 px-[56px] bg-white pb-20'>
                    <span className='prof-card-tips '>Tips</span>
                    <span className='prof-card-tips  my-2'>Feeling stuck?</span>

                    <Link className='mt-4' to='#'>
                      <span className='prof-card-tips '>CV Writing</span>
                    </Link>
                    <Link className='mt-2' to='#'>
                      <span className='prof-card-tips '>
                        Book a counselling session
                      </span>
                    </Link>
                    <Link className='mt-2' to='#'>
                      <span className='prof-card-tips '>Interview tips</span>
                    </Link>
                    <Link className='mt-2' to='#'>
                      <span className='prof-card-tips '>
                        Skills endordement
                      </span>
                    </Link>
                  </div> */}
                </>
              )}
            </div>
          </div>

          <div className='w-full'>
            {editProfile ? (
              <>
                <div className='w-full bg-white mb-10 flex flex-col p-10 ml-6 h-fit'>
                  <h3 className='edit-header'>Settings</h3>

                  <div className='mt-5'>
                    <label className=''>Choose visibility</label>
                    <Select
                      defaultValue={user?.usrProfileVisibility}
                      style={{
                        width: "100%",
                        height: "44px",
                      }}
                      options={[
                        {
                          label: "Public",
                          value: "PUBLIC",
                        },
                        {
                          label: "Semi Public",
                          value: "SEMI_PUBLIC",
                        },
                        {
                          label: "Private",
                          value: "PRIVATE",
                        },
                      ]}
                      onChange={(value) => {
                        handleSelectChange(value);
                      }}
                      onDropdownVisibleChange={() => {
                        //trigger something
                      }}
                    />
                  </div>
                  <p className='mt-4 pb-5 edit-visible'>
                    Your visibility is currently set to Public. All profile
                    details are visible to employers
                  </p>
                </div>
              </>
            ) : null}

            {editProfile ? (
              <>
                <div className='flex justify-center items-center mb-5 flex-col'>
                  <h3 className='edit-header'>Preview</h3>
                  <p className='mt-4 pb-5 edit-visible'>
                    Employers will see the following details when they look
                    through your profile
                  </p>
                </div>
              </>
            ) : null}

            {editProfile && user?.usrProfileVisibility === "PRIVATE" ? (
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.3)",
                }}
                className='sticky top-[50%] -right-[10%] z-[1000] flex flex-col justify-center items-center w-full p-6 h-[100px]'
              >
                <MaterialIcon color='#333' size={40} icon='visibility_off' />
                <p className='private-text'>This profile is set to private</p>
              </div>
            ) : null}

            <div
              style={{
                filter:
                  editProfile && user?.usrProfileVisibility === "PRIVATE"
                    ? "blur(5px)"
                    : "blur(0px)",
              }}
              className='bg-white py-6 px-3 w-full ml-6 h-fit pb-12'
            >
              <Descriptions
                colon={false}
                size={"middle"}
                // column={{ xs: 2, sm: 3, md: 4, lg: 6 }}
                column={5}
                title={
                  <span className='prof-info-head'>Basic Information</span>
                }
                layout='vertical'
                items={items}
              />

              <>
                <div
                  style={{
                    background: "#D8D8D88A",
                    height: "1px",
                    width: "100%",
                  }}
                  className='my-10'
                ></div>

                <div className='flex justify-between items-center mb-10'>
                  <span className='prof-info-head'>
                    Job seeking Information
                  </span>

                  {editProfile &&
                  user?.usrProfileVisibility === "SEMI_PUBLIC" ? (
                    <div className='flex'>
                      <Switch
                        style={{
                          width: "50px",
                        }}
                        checked={seekeObj?.jsiPrivacy}
                        onChange={(value) => onJobSeekingChange(value, "item")}
                      />
                      <span className='vis-text w-[114px] ml-3'>
                        Toggle visibility
                      </span>
                    </div>
                  ) : null}
                </div>

                <Descriptions
                  colon={false}
                  size={"middle"}
                  column={2}
                  style={{
                    filter:
                      editProfile && seekeObj?.jsiPrivacy
                        ? "blur(5px)"
                        : "blur(0px)",
                  }}
                  title={""}
                  layout='vertical'
                  items={jobSeeker}
                />

                <div
                  style={{
                    background: "#D8D8D88A",
                    height: "1px",
                    width: "100%",
                  }}
                  className='my-10'
                ></div>

                <div className='flex justify-between items-center mb-5'>
                  <p className='prof-info-head'>Persons with disability</p>
                  {editProfile &&
                  user?.usrProfileVisibility === "SEMI_PUBLIC" &&
                  disabilityObj?.disId ? (
                    <div className='flex'>
                      <Switch
                        style={{
                          width: "50px",
                        }}
                        checked={disabilityObj?.disPrivacy}
                        onChange={() => onDisabilityChange()}
                      />
                      <span className='vis-text w-[114px] ml-3'>
                        Toggle visibility
                      </span>
                    </div>
                  ) : null}
                </div>

                <Descriptions
                  colon={false}
                  size={"middle"}
                  column={3}
                  style={{
                    filter:
                      editProfile && disabilityObj?.disPrivacy
                        ? "blur(5px)"
                        : "blur(0px)",
                  }}
                  title={
                    <>
                      {/* <>
												<p
													style={{
														marginBottom: '20px',
													}}
													className='prof-with-dsablt'
												>
													Are you a person with disability?
												</p>
												<div className='flex items-center justify-between w-[15%] mt-3'>
													{}
													<Checkbox
														checked={
															user?.usrDisability === 'True' ? true : false
														}
														onChange={onChange}
													>
														{user?.usrDisability === 'True' ? 'Yes' : 'No'}
													</Checkbox>
												</div>
											</> */}
                    </>
                  }
                  layout='vertical'
                  items={personDisability}
                />
              </>
            </div>

            <div
              style={{
                filter:
                  editProfile && user?.usrProfileVisibility === "PRIVATE"
                    ? "blur(5px)"
                    : "blur(0px)",
              }}
              className='bg-white p-6 w-full ml-6 h-fit pb-16 mt-5'
            >
              <Tabs defaultActiveKey='1' items={profileTabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
