import React from "react";
import {
  addUrlPrefix,
  getFileNameFromUrl,
  removeDotFromUrl,
} from "../../../../utils";

const url = process.env.REACT_APP_API_BASE_URL;

const FileComponent = ({ fileUrl, fileName }) => {
  return (
    <div className='bg-white p-6 mb-4 mt-10'>
      <div className='w-full flex flex-col'>
        <h3 className='text-darkBlue text-[21px] font-bold'>{fileName}</h3>
        <a
          target='_blank'
          rel='noreferrer'
          className='prof-cert-text text-justify mt-7'
          href={addUrlPrefix(fileUrl)}
        >
          {getFileNameFromUrl(fileUrl)}
        </a>
      </div>
    </div>
  );
};

export default FileComponent;
