import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "antd";
import jobImg from "../assets/jobimg.png";

export default function NeaCard() {
  return (
    <>
      <div className='mb-10'>
        <h1 className='text-darkBlue font-bold text-5xl underline mb-3'>
          CARDS
        </h1>
        <div className='px-14 py-10 bg-white flex flex-col w-full'>
          <div className='mb-4'>
            <h1 className='text-black14 text-[18px] md:text-[40px] font-bold'>
              Your <span className='text-darkBlue'>application</span> status
            </h1>
          </div>
          <p className='text-black333 font-[400]'>
            You can track your job application here.
          </p>
        </div>

        <div className='flex space-x-4 mt-7 bg-white p-5'>
          <div className='flex-shrink-0'>
            <img
              className='w-[70px] h-[75px] rounded-[4px]'
              src={jobImg}
              alt='N'
            />
          </div>
          <div className='flex-1 min-w-0'>
            <p className='text-black14 font-bold text-[17px]'>
              Beta World Developers
            </p>
            <p className='text-black14 font-[500] text-[14px] my-2'>
              Abzed Empire
            </p>

            <div className='flex items-center'>
              <span className='p-1 bg-lightPurple text-darkPurple w-fit rounded-[5px] text-[13px]'>
                Availability: Immediate
              </span>

              <span className='p-1 bg-lightPurple text-darkPurple w-fit rounded-[5px] ml-4 text-[13px]'>
                Intermediate
              </span>
            </div>

            <div className='flex items-center flex-wrap text-blackrgb mt-3'>
              <div className='flex items-center mr-7'>
                <MaterialIcon
                  size={20}
                  color='rgba(20, 20, 20, 0.70)'
                  icon='pin_drop'
                />
                <span className='font-normal text-[13px] ml-1'>Mombasa</span>
              </div>

              <div className='flex items-center mr-7'>
                <MaterialIcon
                  size={20}
                  color='rgba(20, 20, 20, 0.70)'
                  icon='schedule'
                />
                <span className='font-normal text-[13px] ml-1'>Full Time</span>
              </div>

              <div className='flex items-center mr-7'>
                <span className='font-normal text-[13px] ml-1'>
                  KES 100,000
                </span>
              </div>

              <div className='flex items-center mr-7'>
                <MaterialIcon
                  size={20}
                  color='rgba(20, 20, 20, 0.70)'
                  icon='calendar_today'
                />
                <span className='font-normal text-[13px] ml-1'>
                  3 Days Remaining
                </span>
              </div>
            </div>
          </div>
          <div className='inline-flex items-center'>
            <div className='flex flex-col'>
              <span className='p-1 bg-[#FFD06D] text-black333 font-bold w-fit rounded-[5px] text-[14px]'>
                Interview scheduled
              </span>

              <span className='text-black333 ml-4 text-[13px] mt-2'>
                Date: 10th Dec 2023
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
