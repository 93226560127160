import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  Steps,
  theme,
  Button,
  message,
  InputNumber,
  Spin,
  Checkbox,
  Radio,
  DatePicker,
  Tag,
} from "antd";
import moment from "moment/moment";
import { counties } from "../../../counties";
import { handleNextStep } from "../../../features/profile/profileSlice";
import { languages } from "../../../languages";
import { nationalities } from "../../../nationalities";
import { fetchSkillCategory } from "../../../features/skill/skillSlice";
import {
  fetchUserDisabilitys,
  saveUserDisability,
  updateUser,
} from "../../../features/auth/authSlice";
import {
  fetchJobSeekerInfos,
  saveJobSeekerInfo,
} from "../../../features/job/jobSlice";
import dayjs from "dayjs";

const { TextArea } = Input;

let countyOptions = [];

let languageOptions = [];

let nationalityOptions = [];

counties.forEach((item) => {
  countyOptions.push({
    label: item?.name,
    value: item?.name,
  });
});

languages.forEach((item) => {
  languageOptions.push({
    label: item?.name,
    value: item?.name,
  });
});

nationalities.forEach((item) => {
  nationalityOptions.push({
    label: item,
    value: item,
  });
});

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};

export default function PersonalStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user, disability } = useSelector((state) => state.auth);
  const { skillLoading } = useSelector((state) => state.skill);
  const { seekingInfo } = useSelector((state) => state.job);

  const [data, setdata] = useState({});
  const [skillsList, setskillsList] = useState([]);
  const [edit2, setedit2] = useState(false);
  const [seekerObj, setseekerObj] = useState(seekingInfo);
  const [disabilityObj, setdisabilityObj] = useState(disability);
  const [loader, setloader] = useState(false);
  const [hasDis, sethasDis] = useState(disabilityObj?.disIsPresent ?? false);

  const handleChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      objectNumber: "254" + String(e),
    }));
  };

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;
  let minDate = `${yyyy}-${mm}-${dd}`;

  const onFinish = async (values) => {
    values.usrId = user?.usrId;
    values.usrNationalId = user?.usrNationalId;

    if (values?.year || values?.month || values?.day) {
      values.usrDob = `${
        values?.year?.$y ?? new Date(user?.usrDob).getFullYear()
      }-${
        values?.month?.$M < 9
          ? "0" +
            Number((values?.month?.$M ?? new Date(user?.usrDob).getMonth()) + 1)
          : Number((values?.month?.$M ?? new Date(user?.usrDob).getMonth()) + 1)
      }-${
        values?.day?.$D < 10
          ? "0" + values?.day?.$D
          : values?.day?.$D >= 10
          ? values?.day?.$D
          : new Date(user?.usrDob).getDay()
      }`;
    }

    delete values.day;
    delete values.month;
    delete values.year;

    if (values?.usrLanguages) {
      values.usrLanguages = values?.usrLanguages?.toString();
    }

    await dispatch(updateUser(values));
    await setedit2(true);
  };

  const onFinish2 = async (values) => {
    await setloader(true);
    values.disUsrId = user?.usrId;
    values.disCreatedBy = user?.usrId;

    if (disabilityObj?.disId) {
      values.disId = disabilityObj?.disId;
    }

    if (seekerObj?.jsiId) {
      values.jsiId = seekerObj?.jsiId;
    }

    values.jsiUsrId = user?.usrId;
    values.jsiCreatedBy = user?.usrId;

    const jsiObj = Object.keys(values)
      .filter((key) => key.includes("jsi"))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: values[key],
        });
      }, {});

    const disObj = Object.keys(values)
      .filter((key) => key.includes("dis"))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: values[key],
        });
      }, {});

    await dispatch(saveJobSeekerInfo(jsiObj));

    await dispatch(saveUserDisability(disObj));

    await handleFetchJobSeekingInfo();

    await handleFetchDisability();

    await setloader(false);

    await dispatch(handleNextStep());
  };

  const genderOptions = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const maritalOptions = [
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Divorced",
      value: "Divorced",
    },
    {
      label: "Widowed",
      value: "Widowed",
    },
  ];

  const ethnicityOptions = [
    {
      label: "Samburu",
      value: "Samburu",
    },
    {
      label: "Turkana",
      value: "Turkana",
    },
    {
      label: "Swahili",
      value: "Swahili",
    },
    {
      label: "Sakuye",
      value: "Sakuye",
    },
    {
      label: "Maasai",
      value: "Maasai",
    },
    {
      label: "Kamba",
      value: "Kamba",
    },
    {
      label: "Kisii",
      value: "Kisii",
    },
    {
      label: "Somalis",
      value: "Somalis",
    },
    {
      label: "Nilotic",
      value: "Nilotic",
    },
    {
      label: "Meru",
      value: "Meru",
    },
    {
      label: "Bantu",
      value: "Bantu",
    },
    {
      label: "Orma",
      value: "Orma",
    },
    {
      label: "Boorana",
      value: "Boorana",
    },
    {
      label: "Kikuyu",
      value: "Kikuyu",
    },
    {
      label: "Luhya",
      value: "Luhya",
    },
    {
      label: "Luo",
      value: "Luo",
    },
    {
      label: "Kalenjin",
      value: "Kalenjin",
    },
  ];

  const jobSeekingOptions = [
    {
      label: "Letter of application",
      value: "Letter of application",
    },
    {
      label: "CV",
      value: "CV",
    },
    {
      label: "Profile",
      value: "Profile",
    },
  ];

  const unEmpDuration = [
    {
      label: "Less than 6 months",
      value: "Less than 6 months",
    },
    {
      label: "1 year",
      value: "1 year",
    },
    {
      label: "2 - 5 years",
      value: "2 - 5 years",
    },
    {
      label: "5+ years",
      value: "5+ years",
    },
  ];

  const unEmpReason = [
    {
      label: "Recent Graduate",
      value: "Recent Graduate",
    },
    {
      label: "Student",
      value: "Student",
    },
    {
      label: "School Dropout",
      value: "School Dropout",
    },
    {
      label: "Lack of Experience",
      value: "Lack of Experience",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const majorReligions = [
    {
      label: "Christianity",
      value: "Christianity",
    },
    {
      label: "Islam",
      value: "Islam",
    },
    {
      label: "Hinduism",
      value: "Hinduism",
    },
    {
      label: "Buddhism",
      value: "Buddhism",
    },
    {
      label: "Judaism",
      value: "Judaism",
    },
    {
      label: "Sikhism",
      value: "Sikhism",
    },

    {
      label: "Jainism",
      value: "Jainism",
    },

    {
      label: "Taoism",
      value: "Taoism",
    },
  ];

  const disTypeOptions = [
    {
      label: "Intellectual disability",
      value: "Intellectual disability",
    },
    {
      label: "Learning disabilities",
      value: "Learning disabilities",
    },
    {
      label: "Hearing impairment",
      value: "Hearing impairment",
    },
    {
      label: "Physical disability",
      value: "Physical disability",
    },
    {
      label: "Developmental delay",
      value: "Developmental delay",
    },
    {
      label: "Vision impairment",
      value: "Vision impairment",
    },
    {
      label: "Autistic Spectrum Disorders",
      value: "Autistic Spectrum Disorders",
    },
    {
      label: "Cerebral palsy",
      value: "Cerebral palsy",
    },
    {
      label: "Mental disorder",
      value: "Mental disorder",
    },
    {
      label: "Attention deficit hyperactivity disorder",
      value: "Attention deficit hyperactivity disorder",
    },
    {
      label: "Deafblindness",
      value: "Deafblindness",
    },
    {
      label: "Dysgraphia",
      value: "Dysgraphia",
    },
    {
      label: "Dyslexia",
      value: "Dyslexia",
    },
    {
      label: "Traumatic brain injury",
      value: "Traumatic brain injury",
    },
    {
      label: "Epilepsy",
      value: "Epilepsy",
    },
    {
      label: "Spinal cord injury",
      value: "Spinal cord injury",
    },
    {
      label: "Blindness",
      value: "Blindness",
    },
    {
      label: "Spina bifida",
      value: "Spina bifida",
    },
    {
      label: "Neurological disorder",
      value: "Neurological disorder",
    },
    {
      label: "Nonverbal learning disorder",
      value: "Nonverbal learning disorder",
    },
  ];

  async function handleFetchSKillCategory() {
    const res = await dispatch(fetchSkillCategory());
    if (res?.payload?.success) {
      let skills = [];

      await res?.payload?.data?.result?.forEach((item) => {
        skills.push({
          label: item?.sklcatName,
          value: item?.sklcatName,
        });
      });

      await setskillsList(skills);
    }
  }

  async function handleFetchJobSeekingInfo() {
    const usrObj = {
      jsiUsrId: user?.usrId,
    };
    const res = await dispatch(fetchJobSeekerInfos(usrObj));
    if (res?.payload?.success) {
      setseekerObj(res?.payload?.data?.result[0]);
    }
  }

  async function handleFetchDisability() {
    const usrObj = {
      disUsrId: user?.usrId,
    };
    const res = await dispatch(fetchUserDisabilitys(usrObj));
    if (res?.payload?.success) {
      setdisabilityObj(res?.payload?.data?.result[0]);
    }
  }

  useEffect(() => {
    console.log(hasDis);
  }, [user, skillsList, edit2, seekerObj, disabilityObj, hasDis]);

  useEffect(() => {
    setseekerObj(seekingInfo);
  }, [seekingInfo]);

  useEffect(() => {
    setdisabilityObj(disability);
  }, [disability]);

  useEffect(() => {
    handleFetchJobSeekingInfo();
    handleFetchDisability();
  }, []);

  return (
    <>
      <div className='mt-10'>
        <h3 className='edit-step-head'>
          Personal Info {edit2 ? "(Part 2)" : "(Part 1)"}
        </h3>
        <p className='edit-step-desc mt-2'>
          Make sure you give accurate details so that future employers can reach
          you. Fields marked with (*) are mandatory.
        </p>

        {edit2 ? (
          <>
            <Form
              disabled={loader}
              layout='vertical'
              ref={formRef}
              name='control-ref'
              onFinish={onFinish2}
              style={{
                maxWidth: "100%",
                marginTop: 20,
              }}
              form={form}
            >
              <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                <Form.Item
                  name='jsiSeekingMethod'
                  label='Job seeking methods '
                  rules={[
                    {
                      required: seekerObj?.jsiSeekingMethod ? false : true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={seekerObj?.jsiSeekingMethod}
                    options={jobSeekingOptions}
                  />
                </Form.Item>

                <Form.Item
                  name={"jsiMaintenance"}
                  label='current job'
                  rules={[
                    {
                      required: seekerObj?.jsiMaintenance ? false : true,
                      message: "your current employment status",
                    },
                  ]}
                >
                  <Input
                    defaultValue={seekerObj?.jsiMaintenance}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>
                <Form.Item
                  name='disIsPresent'
                  label='Are you a person with disability?'
                >
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={disabilityObj?.disIsPresent}
                    onChange={(value) => sethasDis(value)}
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>

                {hasDis ? (
                  <>
                    <Form.Item
                      name='disType'
                      label='Type of disability present'
                      rules={[
                        {
                          required: disabilityObj?.disType ? false : true,
                          message: "Field is required",
                        },
                      ]}
                    >
                      <Select
                        style={{
                          width: "100%",
                          height: "44px",
                        }}
                        defaultValue={disabilityObj?.disType}
                        options={disTypeOptions}
                      />
                    </Form.Item>

                    <Form.Item
                      name={"disCertNumber"}
                      label='Certificate Number '
                      rules={[
                        {
                          required: disabilityObj?.disCertNumber ? false : true,
                          message: "enter Certificate Number",
                        },
                      ]}
                    >
                      <Input
                        defaultValue={disabilityObj?.disCertNumber}
                        className='rounded-[8px] h-[44px] w-full border border-black'
                      />
                    </Form.Item>
                  </>
                ) : null}
              </div>

              <div className='flex items-center justify-center mt-10 mb-14 w-full'>
                <button
                  onClick={() => setedit2(false)}
                  className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 mr-3'
                  type='button'
                >
                  Back
                </button>

                <button
                  className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3'
                  type='submit'
                >
                  Next
                </button>
              </div>
            </Form>
          </>
        ) : (
          <>
            <Form
              layout='vertical'
              ref={formRef}
              name='control-ref'
              onFinish={onFinish}
              style={{
                maxWidth: "100%",
                marginTop: 20,
              }}
              form={form}
              initialValues={{
                ...user,
                day: user?.usrDob ? dayjs(user?.usrDob) : null,
                month: user?.usrDob ? dayjs(user?.usrDob) : null,
                year: user?.usrDob ? dayjs(user?.usrDob) : null,
              }}
            >
              <div className='grid grid-cols-1 md:grid-cols-3 gap-x-6'>
                <Form.Item
                  name={"usrFirstName"}
                  label='First Name *'
                  rules={[
                    {
                      required: user?.usrFirstName ? false : true,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    defaultValue={user?.usrFirstName}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>
                <Form.Item
                  name={"usrMiddleName"}
                  label='Middle Name *'
                  rules={[
                    {
                      required: user?.usrMiddleName ? false : true,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    defaultValue={user?.usrMiddleName}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>
                <Form.Item
                  name={"usrLastName"}
                  label='Last Name *'
                  rules={[
                    {
                      required: user?.usrLastName ? false : true,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    defaultValue={user?.usrLastName}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                <Form.Item label='ID Number *'>
                  <Input
                    readOnly
                    defaultValue={user?.usrNationalId}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>

                <Form.Item label='Email *'>
                  <Input
                    readOnly
                    defaultValue={user?.usrEmail}
                    type='email'
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>

                <Form.Item label='Phone Number *'>
                  <InputNumber
                    readOnly
                    defaultValue={"+" + user?.usrMobileNumber}
                    maxLength={10}
                    minLength={9}
                    onChange={handleNumberChange}
                    className='rounded-[8px] h-[44px] w-full border border-black'
                  />
                </Form.Item>

                <Form.Item name='usrGender' label='Gender'>
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={user?.usrGender}
                    options={genderOptions}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name='usrCounty'
                  label='County *'
                  rules={[
                    {
                      required: user?.usrCounty ? false : true,
                      message: "Please select your county",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    options={countyOptions}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    defaultValue={user?.usrCounty}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name='usrNationality'
                  label='Nationality *'
                  rules={[
                    {
                      required: user?.usrNationality ? false : true,
                      message: "Nationality is required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={user?.usrGender}
                    options={nationalityOptions}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>

                <Form.Item name='usrEthnicity' label='Ethnicity'>
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={user?.usrEthnicity}
                    options={ethnicityOptions}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>
                <Form.Item name='usrMaritalStatus' label='Marital Status'>
                  <Select
                    defaultValue={user?.usrMaritalStatus}
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    options={maritalOptions}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>

                <Form.Item name='usrLanguages' label='Languages'>
                  <Select
                    mode='multiple'
                    tagRender={tagRender}
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={
                      user?.usrLanguages ? user?.usrLanguages?.split(",") : null
                    }
                    options={languageOptions}
                    // onChange={(value) => {
                    // 	handleSelectChange(value);
                    // }}
                    onDropdownVisibleChange={() => {
                      //trigger something
                    }}
                  />
                </Form.Item>

                <Form.Item name='usrOtherTitle' label='Religion'>
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    defaultValue={user?.usrOtherTitle}
                    options={majorReligions}
                    loading={skillLoading}
                    onChange={(value) => {
                      handleSelectChange(value);
                    }}
                    onDropdownVisibleChange={() => {
                      handleFetchSKillCategory();
                    }}
                  />
                </Form.Item>

                <div>
                  <p className='mb-3 font-medium'>Date of birth *</p>
                  <div className='flex items-center w-full'>
                    <Form.Item
                      name='day'
                      rules={[
                        {
                          required: user?.usrDob ? false : true,
                          message: "Day is required",
                        },
                      ]}
                      className='w-full'
                    >
                      <DatePicker placeholder='Day' format={"DD"} />
                    </Form.Item>

                    <Form.Item
                      name='month'
                      rules={[
                        {
                          required: user?.usrDob ? false : true,
                          message: "Month is required",
                        },
                      ]}
                      className='mr-6 w-full'
                    >
                      <DatePicker
                        placeholder='Month'
                        className='mx-3'
                        format={"MMMM"}
                        picker='month'
                      />
                    </Form.Item>

                    <Form.Item
                      name='year'
                      rules={[
                        {
                          required: user?.usrDob ? false : true,
                          message: "Year is required",
                        },
                      ]}
                      className='w-full'
                    >
                      <DatePicker
                        maxDate={dayjs(minDate)}
                        placeholder='Year'
                        format={"YYYY"}
                        picker='year'
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Form.Item name='usrTitle' label='Job/Career Title'>
                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  defaultValue={user?.usrTitle}
                  options={skillsList}
                  loading={skillLoading}
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  onDropdownVisibleChange={() => {
                    handleFetchSKillCategory();
                  }}
                />
              </Form.Item>
              <div>
                <p className='mb-1 font-medium text-[15px]'>Bio</p>
                <p className='label-info'>
                  Tell future employers a little about yourself.
                </p>
                <div className='w-full mt-5'>
                  <Form.Item name='usrBio' className='w-full'>
                    <TextArea defaultValue={user?.usrBio} rows={4} />
                  </Form.Item>
                </div>
              </div>
              <div className='flex items-center justify-center mt-10 mb-14 w-full'>
                <button
                  className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3'
                  type='submit'
                >
                  Next
                </button>
              </div>
            </Form>
          </>
        )}
      </div>
    </>
  );
}
