import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  Steps,
  theme,
  Button,
  message,
  InputNumber,
  Spin,
  Checkbox,
  Radio,
  DatePicker,
  Upload,
} from "antd";
import moment from "moment/moment";
import { counties } from "../../../counties";
import {
  handleNextStep,
  handlePrevStep,
} from "../../../features/profile/profileSlice";
import docImg from "../../../assets/docImg.png";
import {
  fetchAttachments,
  saveAttachment,
} from "../../../features/doc/docSlice";
import { fetchSkillCategory } from "../../../features/skill/skillSlice";
import { addUrlPrefix, removeDotFromUrl } from "../../../utils";
import {
  fetchEducations,
  fetchProfessional,
} from "../../../features/edu/eduSlice";
import { fetchExperiences } from "../../../features/exp/expSlice";
import FileComponent from "./components/FileComponent";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

let countyOptions = [];

counties.forEach((item) => {
  countyOptions.push({
    label: item?.name,
    value: item?.name,
  });
});

export default function DocumentStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { docs, docLoading } = useSelector((state) => state.doc);
  const { educations, professional } = useSelector((state) => state.edu);
  const { skillLoading } = useSelector((state) => state.skill);

  const [data, setdata] = useState({});
  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const [docList, setdocList] = useState(docs);
  const [addEducation, setaddEducation] = useState(false);
  const [editedItem, seteditedItem] = useState({});
  const [editing, setediting] = useState(false);
  const [skillCategoryList, setskillCategoryList] = useState([]);
  const [academic, setacademic] = useState(false);

  const usrObj = {
    usrId: user?.usrId,
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    const edu = await dispatch(fetchEducations(usrObj));
    const pro = await dispatch(fetchProfessional(usrObj));
  };

  const props = {
    name: "file",
    multiple: false,
    accept: "image/png, image/jpeg, .pdf",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleCOuntyChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      conCounty: value,
    }));
  }

  const handleDateChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      attCourseDate: e,
    }));
  };

  const handleEditJobCategoryChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      attJobCategory: e,
    }));
  };

  function handleGoNext() {
    dispatch(handleNextStep());
    // navigate("/profile");
  }

  function handleGoBack() {
    form.resetFields();
    seteditedItem({});
    setaddEducation(false);
    dispatch(handlePrevStep());
  }

  async function handleCancel() {
    await seteditedItem({});
    // await form2.resetFields()
    await setediting(false);
    await setfileUrl("");
    setfileName("");
    await setaddEducation(false);
  }

  async function handleaddNew() {
    await seteditedItem({});
    await setediting(false);
    await setaddEducation(true);
  }

  async function handleEditItem(e, item) {
    await e.preventDefault();
    await seteditedItem(item);
    await setediting(true);
    await setaddEducation(true);
  }

  const onFinish2 = async () => {
    await dispatch(saveAttachment(editedItem));
    const resp = await dispatch(fetchAttachments(usrObj));
    if (resp?.payload?.success) {
      await setdocList(resp?.payload?.data?.result);
    }
    await setaddEducation(false);
    await form.resetFields();
  };

  const onFinish = async (values) => {
    values.attFile = fileUrl;
    values.attInstId = user?.usrId;
    values.attCreatedBy = user?.usrId;
    values.attType = "JOBSEEKER";
    values.attFileName = fileName;

    await dispatch(saveAttachment(values));
    const resp = await dispatch(fetchAttachments(usrObj));
    if (resp?.payload?.success) {
      await setdocList(resp?.payload?.data?.result);
    }

    await setaddEducation(false);
    await setfileUrl("");
    setfileName("");
    await form.resetFields();
  };

  async function handleFetchDocs() {
    const resp = await dispatch(fetchAttachments(usrObj));

    if (resp?.payload?.success) {
      await setdocList(resp?.payload?.data?.result);
    }
  }

  async function handleFetchSKillCategory() {
    const res = await dispatch(fetchSkillCategory());
    if (res?.payload?.success) {
      let skills = [];

      await res?.payload?.data?.result?.forEach((item) => {
        skills.push({
          label: item?.sklcatName,
          value: item?.sklcatName,
        });
      });

      await setskillCategoryList(skills);
    }
  }

  useEffect(() => {}, [docList, editedItem]);

  useEffect(() => {
    setdocList(docs);
  }, [docs]);

  useEffect(() => {
    handleFetchDocs();
  }, []);

  const EducationElement = educations.map((item) => (
    <FileComponent fileUrl={item.eduDesc} fileName={item.eduSchool} />
  ));

  const ProfessionalElement = professional.map((item) => (
    <FileComponent fileUrl={item.eduDesc} fileName={item.eduSchool} />
  ));

  return (
    <>
      <div className='mt-10'>
        <h3 className='edit-step-head'>Certificates</h3>
        <h4>
          Below is a summary of the documents you have uploaded in each Category
        </h4>
        <br />
        <h2 className='text-2xl'>Education</h2>
        <h2>Education related certificates</h2>
        {EducationElement}
        <h2 className='text-2xl'>Professional Trainings</h2>
        <h2>Professional related certificates</h2>
        {ProfessionalElement}
        <br />
        <h3 className='edit-step-head'>Additional Certificates</h3>
        <p className='edit-step-desc mt-2'>
          uploads documents to support your application including Cv and Cover
          letter
        </p>

        <div className='flex items-center justify-between w-full mt-5'>
          <div className='flex items-center'>
            {addEducation ? (
              <>
                <Checkbox
                  checked={!academic}
                  onChange={() => setacademic(!academic)}
                >
                  Professional
                </Checkbox>
                <Checkbox
                  className='!ml-7'
                  checked={academic}
                  onChange={() => setacademic(!academic)}
                >
                  Academic
                </Checkbox>
              </>
            ) : null}
          </div>

          <button
            onClick={() => setaddEducation(true)}
            className='bg-transparent flex items-center border-none'
          >
            <div className='bg-darkBlue rounded-full p-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clip-rule='evenodd'
                  d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
                  fill='white'
                />
                <path
                  fillRule='evenodd'
                  clip-rule='evenodd'
                  d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
                  fill='white'
                />
              </svg>
            </div>
            <span className='text-darkBlue font-medium text-[17px] ml-3'>
              Add Certificate
            </span>
          </button>
        </div>

        {docList?.length && !addEducation ? (
          <>
            {docList?.map((item) => {
              return (
                <>
                  <div className='bg-white p-6 mb-4 mt-10'>
                    <a
                      onClick={(e) => handleEditItem(e, item)}
                      href='#to'
                      className='flex items-center justify-end mb-3'
                    >
                      <div className='flex items-center'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <g clipPath='url(#clip0_3807_32249)'>
                            <path
                              d='M3 17.2525V21.0025H6.75L17.81 9.9425L14.06 6.1925L3 17.2525ZM20.71 7.0425C21.1 6.6525 21.1 6.0225 20.71 5.6325L18.37 3.2925C17.98 2.9025 17.35 2.9025 16.96 3.2925L15.13 5.1225L18.88 8.8725L20.71 7.0425Z'
                              fill='#0873B9'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_3807_32249'>
                              <rect width='24' height='24' fill='white' />
                            </clipPath>
                          </defs>
                        </svg>

                        <Link to='#' className='prof-edit ml-1'>
                          Edit
                        </Link>
                      </div>
                    </a>

                    <div className='w-full flex flex-col'>
                      <h3 className='text-darkBlue text-[21px] font-bold'>
                        {item?.attCertName}
                      </h3>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        className='prof-cert-text text-justify mt-7'
                        href={addUrlPrefix(item?.attFile)}
                      >
                        {item?.attFileName}
                      </a>
                    </div>
                  </div>
                </>
              );
            })}

            <div className='mt-20'></div>

            <div className='absolute bottom-10 flex justify-center items-center  w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : !addEducation && !docList?.length ? (
          <>
            <div className='flex items-center justify-center flex-col mt-10'>
              <img
                className='object-contain w-[550px] h-auto'
                src={docImg}
                alt='education'
              />
              <p className='edit-step-desc my-10'>
                Don’t have a certificate? You can skip this part
              </p>
            </div>

            <div className='flex items-center justify-center mt-10 mb-14 w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : editing ? (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish2}
            style={{
              maxWidth: "100%",
              marginTop: 20,
            }}
            form={form2}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <div className='flex flex-col mb-5'>
                <label className='mb-2'>Name of Certificate</label>
                <Input
                  value={editedItem?.attCertName}
                  name='attCertName'
                  onChange={handleChange}
                  className='rounded-[8px] h-[44px] w-full border border-black'
                />
              </div>

              <div className='flex flex-col mb-5'>
                <label className='mb-2'>Certificate</label>
                <Dragger
                  defaultFileList={[
                    {
                      uid: editedItem?.attId,
                      name: editedItem?.attFileName,
                      status: "done",
                      url: url + editedItem?.attFile,
                    },
                  ]}
                  {...props}
                >
                  <div className='flex justify-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='29'
                      height='29'
                      viewBox='0 0 29 29'
                      fill='none'
                    >
                      <path
                        d='M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M20.5423 9.66667L14.5007 3.625L8.45898 9.66667'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M14.5 3.625V18.125'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    <p className='ant-upload-text !ml-2'>Upload pdf or jpeg</p>
                  </div>
                </Dragger>
              </div>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        ) : (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            form={form}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <Form.Item
                name='attCertName'
                label='Name of Certificate'
                rules={[
                  {
                    required: true,
                    message: "Document name is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>

              <Form.Item
                name='attCertNumber'
                label='Certificate Number'
                rules={[
                  {
                    required: true,
                    message: "Document number is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>

              {academic ? null : (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Career path is required",
                    },
                  ]}
                  name='attJobCategory'
                  label='Profession'
                >
                  <Select
                    style={{
                      width: "100%",
                      height: "44px",
                    }}
                    options={skillCategoryList}
                    loading={skillLoading}
                    onDropdownVisibleChange={() => {
                      handleFetchSKillCategory();
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item
                name='attSchool'
                label='Institution'
                rules={[
                  {
                    required: true,
                    message: "Institution name is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Date is required",
                  },
                ]}
                name='attCourseDate'
                label='End Date'
              >
                <DatePicker
                  picker='month'
                  format={"MMMM - YYYY"}
                  className={academic ? "w-full" : "w-full doc-date-picker"}
                />
              </Form.Item>

              <Form.Item
                name='attFile'
                label='Certificate'
                rules={[
                  {
                    required: true,
                    message: "Please upload a file",
                  },
                ]}
              >
                <Dragger {...props}>
                  <div className='flex justify-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='29'
                      height='29'
                      viewBox='0 0 29 29'
                      fill='none'
                    >
                      <path
                        d='M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M20.5423 9.66667L14.5007 3.625L8.45898 9.66667'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M14.5 3.625V18.125'
                        stroke='#147CBC'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    <p className='ant-upload-text !ml-2'>Upload pdf or jpeg</p>
                  </div>
                </Dragger>
              </Form.Item>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}
