import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jobImg from "../../assets/jobimg.png";
import MaterialIcon from "material-icons-react";
import {
  fetchJobRecommendations,
  setActiveJob,
} from "../../features/job/jobSlice";

function DashNotifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { refJobs, recJobs } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth);

  const [jobList, setjobList] = useState(refJobs);
  const [recList, setrecList] = useState(recJobs);

  const usrObj = {
    jbrUsrId: user?.usrId,
  };

  async function handleActiveJob(e, item) {
    e.preventDefault();
    await dispatch(setActiveJob(item));
    await navigate(
      `/job-info/${item?.jobId}/${item?.jobPosition}`.replace(/\s+/g, "-")
    );
  }

  async function handleFetchJobs() {
    await dispatch(fetchJobRecommendations(usrObj));
  }

  useEffect(() => {}, [jobList, recList]);

  useEffect(() => {
    setjobList(refJobs);
  }, [refJobs]);

  useEffect(() => {
    setrecList(recJobs);
  }, [recJobs]);

  useEffect(() => {
    handleFetchJobs();
  }, []);

  return (
    <>
      <div className='hidden max-h-[100vh] h-fit'>
        <div className='mb-10'>
          <h3 className='not-header mb-7'>Referrals</h3>
          <ol className='divide-y divider-gray-200'>
            {jobList?.length ? (
              jobList.map((item) => {
                return (
                  <>
                    <li className='mb-3'>
                      <Link
                        onClick={(e) => handleActiveJob(e, item)}
                        to={""}
                        className='items-center block sm:flex hover:bg-gray-100 dark:hover:bg-gray-700 p-2'
                      >
                        <div className='flex items-start'>
                          <img
                            className='w-[62px] h-[62px] mb-3 mr-3 rounded-[4px] sm:mb-0'
                            src={jobImg}
                            alt='user'
                          />

                          <div className='flex flex-col ml-2'>
                            <span className='not-pos'>{item?.jobPosition}</span>
                            <span className='not-inst my-2'>
                              {item?.jobName}
                            </span>
                            <span className='not-inst'>
                              {item?.userName} of {item?.instituteName} has
                              referred you
                            </span>
                            <span className='not-time mt-2'>
                              {moment(item?.jbrCreatedTime)
                                .startOf("day")
                                .fromNow()}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </>
                );
              })
            ) : (
              <span>You have no job referrals</span>
            )}
          </ol>
        </div>
      </div>

      <div className='hidden max-h-[100vh] h-fit overflow-y-auto'>
        <div className='mb-10'>
          <h3 className='not-header mb-7'>Job Recommendations</h3>
          <ol className=''>
            {recList?.length ? (
              recList?.map((item) => {
                return (
                  <>
                    <li className='mb-3'>
                      <Link
                        to={""}
                        className='items-center block sm:flex hover:bg-gray-100 dark:hover:bg-gray-700 p-2'
                      >
                        <div className='flex items-start'>
                          <img
                            className='w-[62px] h-[62px] mb-3 mr-3 rounded-[4px] sm:mb-0'
                            src={jobImg}
                            alt='user'
                          />

                          <div className='flex flex-col ml-2'>
                            <span className='not-pos'>{item?.jobPosition}</span>
                            <span className='not-inst my-2'>
                              {item?.jobName}
                            </span>
                            <div className='flex items-center flex-wrap text-blackrgb'>
                              <div className='flex items-center mr-4'>
                                <MaterialIcon
                                  size={21}
                                  color='rgba(20, 20, 20, 0.70)'
                                  icon='pin_drop'
                                />
                                <span className='font-normal text-[14px] ml-1'>
                                  {item?.jobCity}
                                </span>
                              </div>

                              <div className='flex items-center mr-4'>
                                <MaterialIcon
                                  size={21}
                                  color='rgba(20, 20, 20, 0.70)'
                                  icon='schedule'
                                />
                                <span className='font-normal text-[14px] ml-1'>
                                  {item?.jobType}
                                </span>
                              </div>

                              <div className='flex items-center'>
                                <span className='font-normal text-[15px] ml-1'>
                                  {item?.jobGrossPay
                                    ? "KES " + item?.jobGrossPay
                                    : "Confidential"}
                                </span>
                              </div>
                            </div>
                            {/* <span className='not-time mt-2'>
															Recommended by Paul Mwangi
														</span> */}
                          </div>
                        </div>
                      </Link>
                    </li>
                  </>
                );
              })
            ) : (
              <span>You have no job recommendations</span>
            )}
          </ol>
        </div>
      </div>
    </>
  );
}

export default DashNotifications;
