import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Steps } from "antd";
import PersonalStep from "./PeronalStep";
import EducationStep from "./EducationStep";
import ExperienceStep from "./ExperienceStep";
import SkillStep from "./SkillStep";
import DocumentStep from "./DocumentStep";
import ContactPersonSTep from "./ContactPersonSTep";
import ProfessionalQualification from "./ProessionalQualification";
import DeclarationStep from "./DeclarationStep";

export default function BuildProfile() {
  // const navigate = useNavigate()
  // const dispatch = useDispatch();
  const { stepCurrent } = useSelector((state) => state.profile);

  const [current, setcurrent] = useState(stepCurrent);

  const steps = [
    {
      title: "Personal Info",
      key: 1,
      content: <PersonalStep />,
      icon: <span>1</span>,
    },
    {
      title: "Education",
      key: 2,
      content: <EducationStep />,
      icon: <span>2</span>,
    },
    {
      title: "Professional Qualification",
      key: 3,
      content: <ProfessionalQualification />,
      icon: <span>3</span>,
    },
    {
      title: "Experience",
      key: 4,
      content: <ExperienceStep />,
      icon: <span>4</span>,
    },
    {
      title: "Documents",
      key: 5,
      content: <DocumentStep />,
      icon: <span>5</span>,
    },
    {
      title: "Declaration",
      key: 6,
      content: <DeclarationStep />,

      icon: <span>6</span>,
    },
  ];

  const items = steps.map((item) => ({
    key: item.key,
    title: item.title,
    icon: item.icon,
  }));

  useEffect(() => {}, [current]);

  useEffect(() => {
    setcurrent(stepCurrent);
  }, [stepCurrent]);

  return (
    <>
      <div className='w-[95%] mx-[42px] '>
        <h2 className='prof-name'>
          Edit your <span className='text-[#147CBC]'>profile</span>
        </h2>
        <p className='edit-prof-desc mb-7'>
          Let’s build a profile that will stand out to employers
        </p>
        <div className='hidden md:flex flex-col'>
          <Steps
            className='w-full'
            current={current}
            size='small'
            labelPlacement='vertical'
            items={items}
          />
        </div>

        <div>{steps[current].content}</div>
      </div>
    </>
  );
}
