import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Tabs } from "antd";
import InfoTabs from "./clearance_tabs/InfoTab";
import EmployerTab from "./clearance_tabs/EmployerTab";
import JobDetailsTab from "./clearance_tabs/JobDetailsTab";
import {
  fetchForeignClearances,
  saveForeignClearance,
} from "../../features/job/jobSlice";
import toast from "react-hot-toast";
import ForeignJobsApplciation from "./ForeignJobApplications";

export default function ForeignJobClearance() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);

  const usrObj = {
    focUsrId: user?.usrId,
  };

  const [current, setcurrent] = useState("1");
  const [offLetterUrl, setoffLetterUrl] = useState("");
  const [serveTermUrl, setserveTermUrl] = useState("");
  const [contractUrl, setcontractUrl] = useState("");
  const [departUrl, setdepartUrl] = useState("");
  const [apply, setapply] = useState(false);

  function handleUrlChange(value) {
    setoffLetterUrl(value);
  }

  function handleUrl2Change(value) {
    setserveTermUrl(value);
  }

  function handleUrl3Change(value) {
    setcontractUrl(value);
  }

  function handleUrl4Change(value) {
    setdepartUrl(value);
  }

  const tabs = [
    {
      label: <span className='ml-2'>Personal Info</span>,
      key: "1",
      children: <InfoTabs handleCurrentTab={handleCurrentTab} />,
    },
    {
      label: <span className='ml-2'>Foreign employer details</span>,
      key: "2",
      children: <EmployerTab form={form} handleCurrentTab={handleCurrentTab} />,
    },
    {
      label: <span className='ml-2'>Foreign job details</span>,
      key: "3",
      children: (
        <JobDetailsTab
          handleCurrentTab={handleCurrentTab}
          handleUrlChange={handleUrlChange}
          handleUrl2Change={handleUrl2Change}
          handleUrl3Change={handleUrl3Change}
          handleUrl4Change={handleUrl4Change}
        />
      ),
    },
  ];

  function handleCurrentTab(key) {
    setcurrent(key);
  }

  async function handleFetchJobs() {
    await dispatch(fetchForeignClearances(usrObj));
  }

  const onFinish = async (data) => {
    data.focOfficeMobile = "254" + String(data.focOfficeMobile);
    data.focTelPhone = "254" + String(data.focTelPhone);
    data.focUsrId = user?.usrId;
    data.focCreatedBy = user?.usrId;
    data.focOfferLetter = offLetterUrl;
    data.focServiceTerms = serveTermUrl;
    data.focContractFile = contractUrl;
    data.focPreDepartureCertFile = departUrl;
    data.focDepartureDate = data.focDepartureDate.$d;
    data.focPreDepartureGraduationDate = data.focPreDepartureGraduationDate.$d;
    data.focValidityEndDate = data.focValidityEndDate.$d;
    data.focValidityStartDate = data.focValidityStartDate.$d;

    console.log(data);

    const res = await dispatch(saveForeignClearance(data));

    if (res?.payload?.success) {
      toast.success("Application successful. Please wait for feedback");
      await handleFetchJobs();
      await setapply(false);
    } else {
      toast(
        "We cannot process your request at the moment please try again later"
      );
    }
  };

  return (
    <>
      <div className='m-[10px] md:mr-[108px] md:ml-[42px] md:mt-[15px]'>
        <div className='flex items-center justify-between'>
          <div className='bg-white p-[53px]'>
            <h2 className='prof-name'>
              Foreign <span className='text-[#147CBC]'>job</span> clearance.
            </h2>
            <p className='prof-desc mt-2'>
              {apply
                ? "Fill in the following form to apply for foreign job clearance"
                : "Foreign jobs clearance status"}
            </p>
          </div>
          {apply ? null : (
            <>
              <button
                onClick={() => setapply(true)}
                className='bg-transparent flex items-center border-none'
              >
                <div className='bg-darkBlue rounded-full p-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clip-rule='evenodd'
                      d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
                      fill='white'
                    />
                    <path
                      fillRule='evenodd'
                      clip-rule='evenodd'
                      d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
                      fill='white'
                    />
                  </svg>
                </div>
                <span className='text-darkBlue font-medium text-[17px] ml-3'>
                  Start application
                </span>
              </button>
            </>
          )}
        </div>

        {apply ? (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <div className='bg-white p-6 w-full h-fit pb-16 mt-5'>
              <Tabs activeKey={current} defaultActiveKey='1' items={tabs} />
            </div>
          </Form>
        ) : (
          <>
            <ForeignJobsApplciation />
          </>
        )}
      </div>
    </>
  );
}
