import Header from "../../layouts/Header";
import JobVacancy from "../job_vacancy/JobVacancy";
export default function Home() {
  return (
    <>
      <Header />
      <div className=' h-[100vh]'>
        <JobVacancy />
      </div>
    </>
  );
}
