import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { handleStepCurrent } from "../../../features/profile/profileSlice";
import { fetchEducations, saveEducation } from "../../../features/edu/eduSlice";
import { Spin, Switch } from "antd";
import moment from "moment";
import {
  addUrlPrefix,
  getFileNameFromUrl,
  removeDotFromUrl,
} from "../../../utils";

const url = process.env.REACT_APP_API_BASE_URL;

export default function EducationTab({ profileApplication, editProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { educations, eduLoading } = useSelector((state) => state.edu);

  const [educationList, seteducationList] = useState(educations);

  const usrObj = {
    usrId: user?.usrId,
  };

  async function handleProfileBuilder() {
    await dispatch(handleStepCurrent(1));
    await navigate("/profile-builder");
  }

  async function handleFetchEducations() {
    const resp = await dispatch(fetchEducations(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }
  }

  const onChange = async (checked, item) => {
    let objCopy = { ...item, eduPrivacy: !item?.eduPrivacy };
    const res = await dispatch(saveEducation(objCopy));
    if (res?.payload?.success) {
      handleFetchEducations();
    }
  };

  useEffect(() => {}, [educationList]);

  useEffect(() => {
    seteducationList(educations);
  }, [educations]);

  useEffect(() => {
    handleFetchEducations();
  }, []);

  return (
    <>
      <div className='flex justify-end'>
        {profileApplication || editProfile ? null : (
          <button
            onClick={handleProfileBuilder}
            className='bg-transparent flex items-center border-none my-5'
          >
            <div className='bg-darkBlue rounded-full p-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clip-rule='evenodd'
                  d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
                  fill='white'
                />
                <path
                  fillRule='evenodd'
                  clip-rule='evenodd'
                  d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
                  fill='white'
                />
              </svg>
            </div>
            <span className='text-darkBlue font-medium text-[17px] ml-3'>
              Add Education
            </span>
          </button>
        )}
      </div>

      {eduLoading && <Spin size='large' />}

      {!eduLoading && educationList?.length ? (
        educationList.map((item) => {
          return (
            <div
              key={item?.eduId}
              // style={{
              // 	display: item?.eduPrivacy && !editProfile && user?.usrProfileVisibility === 'SEMI_PUBLIC' ? 'none' : 'block',
              // }}
            >
              <div className='bg-white p-6 flex items-center justify-between w-full'>
                <div
                  style={{
                    filter:
                      item?.eduPrivacy &&
                      editProfile &&
                      user?.usrProfileVisibility === "SEMI_PUBLIC"
                        ? "blur(5px)"
                        : "blur(0px)",
                  }}
                  className='w-full flex flex-col'
                >
                  <h3 className='text-darkBlue text-[21px] font-bold'>
                    {item?.eduLevel}
                  </h3>
                  <p className='text-black333 font-medium text-[18px] my-1'>
                    {item?.eduSchool}
                  </p>
                  <span className='text-black333 font-normal text-[19px]'>
                    {moment(item?.eduStartDate).format("MMMM YYYY")} -{" "}
                    {moment(item?.endEndDate).format("MMMM YYYY")}
                  </span>

                  <a
                    target='_blank'
                    rel='noreferrer'
                    className='prof-cert-text text-justify mt-7'
                    href={addUrlPrefix(item?.eduDesc)}
                  >
                    {getFileNameFromUrl(item?.eduDesc)}
                  </a>
                </div>
                {editProfile && user?.usrProfileVisibility === "SEMI_PUBLIC" ? (
                  <div className='flex flex-col items-center justify-center'>
                    <Switch
                      style={{
                        width: "50px",
                      }}
                      checked={item?.eduPrivacy}
                      onChange={(value) => onChange(value, item)}
                    />
                    <span className='vis-text w-[114px] mt-5'>
                      Toggle visibility
                    </span>
                  </div>
                ) : null}
              </div>

              <div
                style={{
                  background: "#D8D8D88A",
                  height: "1px",
                  width: "100%",
                }}
                className='my-10'
              ></div>
            </div>
          );
        })
      ) : (
        <div className='flex items-center justify-center w-full h-full'>
          {/* <h2 className='text-center text-5xl text-gray-400'>Educations will appear here</h2> */}
        </div>
      )}
    </>
  );
}
