import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Tabs } from "antd";
import JobCard from "./JobCard";
import { fetchJobs } from "../../features/job/jobSlice";

const profileTabs = [
  {
    label: <span className='ml-2'>All jobs</span>,
    key: "1",
    children: <JobCard latest={false} closed={false} />,
  },
  {
    label: <span className='ml-2'>Open</span>,
    key: "2",
    children: <JobCard latest={true} closed={false} />,
  },
  {
    label: <span className='ml-2'>Closed</span>,
    key: "5",
    children: <JobCard closed={true} latest={false} />,
  },
];

export default function JobVacancy() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [location, setlocation] = useState("");
  const [type, settype] = useState("");
  const [name, setname] = useState("");
  const [sector, setsector] = useState("");

  const filterObj = {
    jobRecommendation: "",
    jobCountry: location,
    jobCategory: "",
    jobExperienceLevel: "",
    jobType: type,
    jobWorkStyle: "",
    jobName: name,
    jobOccupation: sector,
  };

  async function handleFetchJobs() {
    await dispatch(fetchJobs(filterObj));
  }

  const onSectorChange = async (e) => {
    await setsector(e.target.value);
  };

  const onNameChange = async (e) => {
    await setname(e.target.value);
  };

  const ontypeChange = async (e) => {
    await settype(e.target.value);
  };

  const onLocationChange = async (e) => {
    await setlocation(e.target.value);
  };

  useEffect(() => {
    handleFetchJobs();
  }, [location, type, name, sector]);

  return (
    <>
      <div className='m-[10px] md:mr-[108px] md:ml-[42px] md:mt-[15px]'>
        <div className='bg-white p-5 md:p-[53px]'>
          <h2 className='text-xl md:text-5xl'>
            Hi {user?.usrFullNames?.split(" ").slice(0, -1).join(" ")}, find
            your <span className='text-[#147CBC]'>new job</span> today.
          </h2>
        </div>

        <div className='hidden  flex-col px-10 py-5 mt-7 w-full bg-white  '>
          <div className='flex items-center w-[100%] mb-5'>
            <MaterialIcon color='#147CBC' size={20} icon='search' />
            <span className='text-darkBlue font-[500] ml-2'>
              What kind of job are you looking for?
            </span>
          </div>

          <div className=' items-center justify-center job-filter-border p-1'>
            <div className='flex flex-row items-center w-full'>
              <Input
                suffix={
                  <div className='flex flex-row'>
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_up'
                    />
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_down'
                    />
                  </div>
                }
                className='h-[44px] job-filter'
                placeholder='Jobs by Company'
                bordered={false}
                onChange={onNameChange}
              />
            </div>

            <div className='flex items-center w-full'>
              <Input
                suffix={
                  <div className='flex flex-col'>
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_up'
                    />
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_down'
                    />
                  </div>
                }
                className='h-[44px] job-filter mx-5'
                placeholder='Jobs by Type'
                bordered={false}
                onChange={ontypeChange}
              />
            </div>

            <div className='flex items-center w-full'>
              <Input
                suffix={
                  <div className='flex flex-col'>
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_up'
                    />
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_down'
                    />
                  </div>
                }
                className='h-[44px] job-filter'
                placeholder='Jobs by Sector'
                bordered={false}
                onChange={onSectorChange}
              />
            </div>

            <div className='flex items-center w-full'>
              <Input
                type='text'
                suffix={
                  <div className='flex flex-col'>
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_up'
                    />
                    <MaterialIcon
                      color='#C4C9D3'
                      size={20}
                      icon='arrow_drop_down'
                    />
                  </div>
                }
                className='h-[44px] job-filter ml-5'
                placeholder='Jobs by Location'
                bordered={false}
                onChange={onLocationChange}
              />
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <Tabs className='job-tab' defaultActiveKey='1' items={profileTabs} />
        </div>
      </div>
    </>
  );
}
