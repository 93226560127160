import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Input } from "antd";
import { fetchAppliedJobs } from "../../../features/job/jobSlice";
import jobImg from "../../../assets/jobimg.png";

export default function OfferCard({ props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { offers, reFetch } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth);
  const { appliedJobs } = useSelector((state) => state.job);

  const [showSearch, setshowSearch] = useState(true);
  // const [jobList, setjobList] = useState(offers);

  const jobList = appliedJobs.filter((job) => job.status === "Closed");

  const usrObj = {
    japUsrId: user?.usrId,
    japProgressStatus: "",
    // japProgressStatus: 'OFFER',
  };

  async function handleFetchJobs() {
    await dispatch(fetchAppliedJobs(usrObj));
  }

  async function handleConfirmAvailability(item) {}

  async function handleReschedule(item) {}

  useEffect(() => {}, [jobList]);

  useEffect(() => {}, [offers]);

  useEffect(() => {
    handleFetchJobs();
  }, []);

  useEffect(() => {
    handleFetchJobs();
  }, [reFetch]);

  return (
    <>
      <div
        style={{
          borderRadius: "2px",
          background: "#FBFBFB",
          boxShadow: "0px 0px 8px 0px rgba(167, 167, 167, 0.12)",
        }}
        className='p-3 my-5'
      >
        <div className='p-3 bg-white flex items-center justify-between w-full'>
          <div className='flex items-center w-[70%]'>
            {showSearch ? (
              <MaterialIcon color='#333' size={20} icon='search' />
            ) : null}
            <Input
              className='!placeholder-black333 h-[44px]'
              placeholder={"Search among the jobs you have applied for  "}
              bordered={false}
              onFocus={() => setshowSearch(!showSearch)}
              onBlur={() => setshowSearch(true)}
            />
          </div>

          <button
            className='bg-darkBlue w-[226.503px] p-2 text-white'
            type='button'
          >
            {"Search application"}
          </button>
        </div>
      </div>

      <div className='bg-white p-6 hidden w-[100vw] md:w-[100%] h-fit pb-16 '>
        {jobList?.map((item) => {
          return (
            <>
              <div className='mt-5 mb-3'>
                <h3 className='app-status-info my-7'>
                  Congratulations{" "}
                  {user?.usrFullNames?.split(" ").slice(0, -1).join(" ")}! You
                  got the job!
                </h3>
              </div>
              <div
                style={{
                  border: "1px solid rgba(20, 20, 20, 0.05)",
                }}
                className='rounded-[8px] p-[24px] mb-4'
              >
                <div className='hidden md:flex items-center justify-between'>
                  <div className='flex'>
                    <img
                      className='w-[70px] h-[75px] rounded-[4px]'
                      src={jobImg}
                      alt='N'
                    />

                    <div className='ml-3 flex flex-col'>
                      <p className='text-black14 font-[500] text-[12px] md:text-[18px] mt-1'>
                        {item?.jobPosition}
                      </p>
                      <p className='text-[#8F8C8C] font-medium text-[12px] md:text-[16px]'>
                        {item?.jobName}
                      </p>
                    </div>
                  </div>

                  <div className='flex w-fit rounded-[5px] py-1 px-3 items-center bg-[#EAFFEA]'>
                    <span className=' text-[#006F30] font-medium text-[10px] md:text-[14px] ml-1'>
                      You got the job!
                    </span>
                  </div>
                </div>
                <div className=''>
                  <div className='flex flex-col'>
                    <p
                      style={{
                        borderTop: "1px solid #D8D8D88A",
                        width: "90%",
                      }}
                      className='offer-text mb-5 mt-14 pt-5'
                    >
                      This is when you are expected to start
                    </p>
                    <div className=''>
                      <div className='grid grid-cols-6 gap-5 py-2'>
                        <div className='flex items-center'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='28'
                            height='28'
                            viewBox='0 0 28 28'
                            fill='none'
                          >
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H22.75C23.7165 3.5 24.5 4.2835 24.5 5.25V22.75C24.5 23.7165 23.7165 24.5 22.75 24.5H5.25C4.2835 24.5 3.5 23.7165 3.5 22.75V5.25ZM22.75 5.25H5.25V22.75H22.75V5.25Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M19.25 1.75C19.7332 1.75 20.125 2.14175 20.125 2.625V6.125C20.125 6.60825 19.7332 7 19.25 7C18.7668 7 18.375 6.60825 18.375 6.125V2.625C18.375 2.14175 18.7668 1.75 19.25 1.75Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M8.75 1.75C9.23325 1.75 9.625 2.14175 9.625 2.625V6.125C9.625 6.60825 9.23325 7 8.75 7C8.26675 7 7.875 6.60825 7.875 6.125V2.625C7.875 2.14175 8.26675 1.75 8.75 1.75Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M3.5 9.625C3.5 9.14175 3.89175 8.75 4.375 8.75H23.625C24.1082 8.75 24.5 9.14175 24.5 9.625C24.5 10.1082 24.1082 10.5 23.625 10.5H4.375C3.89175 10.5 3.5 10.1082 3.5 9.625Z'
                              fill='#888888'
                            />
                          </svg>
                          <span className='app-intv-gray ml-1'>Date</span>
                        </div>

                        <span className='app-intv-black ml-1 col-span-3'>
                          {moment(item?.japStartWorkDate).format(
                            "Do MMMM YYYY"
                          )}
                        </span>
                      </div>

                      <div className='grid grid-cols-6 gap-5 py-2'>
                        <div className='flex items-center'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='28'
                            height='28'
                            viewBox='0 0 28 28'
                            fill='none'
                          >
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M5.25 25.375C5.25 24.8918 5.64175 24.5 6.125 24.5H21.875C22.3582 24.5 22.75 24.8918 22.75 25.375C22.75 25.8582 22.3582 26.25 21.875 26.25H6.125C5.64175 26.25 5.25 25.8582 5.25 25.375Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M14 8.75C12.5503 8.75 11.375 9.92525 11.375 11.375C11.375 12.8247 12.5503 14 14 14C15.4497 14 16.625 12.8247 16.625 11.375C16.625 9.92525 15.4497 8.75 14 8.75ZM9.625 11.375C9.625 8.95875 11.5838 7 14 7C16.4162 7 18.375 8.95875 18.375 11.375C18.375 13.7912 16.4162 15.75 14 15.75C11.5838 15.75 9.625 13.7912 9.625 11.375Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M14 3.5C11.9114 3.5 9.90838 4.32968 8.43153 5.80653C6.95468 7.28338 6.125 9.28642 6.125 11.375C6.125 14.9975 8.14787 18.2959 10.2853 20.754C11.3433 21.9707 12.4036 22.9517 13.1995 23.6282C13.5143 23.8958 13.7867 24.1149 14 24.2812C14.2133 24.1149 14.4857 23.8958 14.8005 23.6282C15.5964 22.9517 16.6567 21.9707 17.7147 20.754C19.8521 18.2959 21.875 14.9975 21.875 11.375C21.875 9.28642 21.0453 7.28338 19.5685 5.80653C18.0916 4.32968 16.0886 3.5 14 3.5ZM14 25.375C13.4982 26.0918 13.498 26.0917 13.4977 26.0915L13.497 26.091L13.4949 26.0895L13.4885 26.085L13.4662 26.0691C13.4473 26.0556 13.4203 26.0362 13.3858 26.011C13.3168 25.9607 13.2177 25.8872 13.0927 25.7916C12.8428 25.6006 12.4891 25.3211 12.0661 24.9616C11.2214 24.2436 10.0942 23.2012 8.96472 21.9023C6.72713 19.3291 4.375 15.6275 4.375 11.375C4.375 8.82229 5.38906 6.37414 7.1941 4.5691C8.99914 2.76406 11.4473 1.75 14 1.75C16.5527 1.75 19.0009 2.76406 20.8059 4.5691C22.6109 6.37414 23.625 8.82229 23.625 11.375C23.625 15.6275 21.2729 19.3291 19.0353 21.9023C17.9058 23.2012 16.7786 24.2436 15.9339 24.9616C15.5109 25.3211 15.1572 25.6006 14.9073 25.7916C14.7823 25.8872 14.6832 25.9607 14.6142 26.011C14.5797 26.0362 14.5527 26.0556 14.5338 26.0691L14.5115 26.085L14.5051 26.0895L14.503 26.091L14.5023 26.0915C14.502 26.0917 14.5018 26.0918 14 25.375ZM14 25.375L14.5018 26.0918C14.2005 26.3027 13.7995 26.3027 13.4982 26.0918L14 25.375Z'
                              fill='#888888'
                            />
                          </svg>
                          <span className='app-intv-gray ml-1'>Venue</span>
                        </div>

                        <span className='app-intv-black ml-1 col-span-3'>
                          {item?.japWorkingVenue}
                        </span>
                      </div>

                      <div className='grid grid-cols-6 gap-5 py-2'>
                        <div className='flex items-center'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='28'
                            height='28'
                            viewBox='0 0 28 28'
                            fill='none'
                          >
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M14 4.375C8.68426 4.375 4.375 8.68426 4.375 14C4.375 19.3157 8.68426 23.625 14 23.625C19.3157 23.625 23.625 19.3157 23.625 14C23.625 8.68426 19.3157 4.375 14 4.375ZM2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14Z'
                              fill='#888888'
                            />
                            <path
                              fillRule='evenodd'
                              clip-rule='evenodd'
                              d='M14 7C14.4832 7 14.875 7.39175 14.875 7.875V13.125H20.125C20.6082 13.125 21 13.5168 21 14C21 14.4832 20.6082 14.875 20.125 14.875H14C13.5168 14.875 13.125 14.4832 13.125 14V7.875C13.125 7.39175 13.5168 7 14 7Z'
                              fill='#888888'
                            />
                          </svg>
                          <span className='app-intv-gray ml-1'>Time</span>
                        </div>

                        <span className='app-intv-black ml-1 col-span-3'>
                          {moment(item?.japStartWorkTime).format("hh:mm a")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
